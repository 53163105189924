import Vue from 'vue';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css'; // 引入 Element 的样式文件
import App from './App.vue';
import router from './router'; // 引入路由

Vue.config.productionTip = false;
Vue.use(ElementUI); // 全局注册 Element UI

new Vue({
  router, // 使用路由
  render: h => h(App),
}).$mount('#app');
