<template>
  <div class="match-search-container">
    <div class="content">
      <h1 class="title">川发好基友</h1>
      <div class="input-container">
        <el-input
            v-model="playerOne"
            placeholder="输入第一个召唤师名称"
            class="search-box"
            @keyup.enter="searchMatches"
            clearable
        />
        <el-input
            v-model="playerTwo"
            placeholder="输入第二个召唤师名称"
            class="search-box"
            @keyup.enter="searchMatches"
            clearable
        />
        <el-button @click="searchMatches" class="search-button">搜索</el-button>
      </div>

      <el-row :gutter="20">
        <el-col :span="12">
          <el-card v-if="matchData" class="match-card">
            <h2>合作比赛信息</h2>
            <p>总场次: {{ matchData.match_count }}</p>
            <p>胜场: {{ matchData.win_count }}</p>
            <p>胜率: {{ matchData.win_percent }}%</p>
          </el-card>
        </el-col>

        <el-col :span="12">
          <el-card v-if="vsData" class="match-card">
            <h2>对抗比赛信息</h2>
            <p>总场次: {{ vsData.match_count }}</p>
            <p>{{ vsData.base_player_name }} 胜率: {{ vsData.base_player_win_percent }}%</p>
            <p>{{ vsData.vs_player_name }} 胜率: {{ vsData.vs_player_win_percent }}%</p>
          </el-card>
        </el-col>
      </el-row>

    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'MatchSearchPage',
  data() {
    return {
      playerOne: '',
      playerTwo: '',
      matchData: null,
      vsData: null,
      loading: false,
      error: null,
    };
  },
  methods: {
    async searchMatches() {
      if (!this.playerOne || !this.playerTwo) {
        this.error = "请输入两个召唤师名称.";
        return;
      }

      this.loading = true;
      this.error = null;
      this.matchData = null;
      this.vsData = null;

      try {
        const response = await axios.get(`/api/lol/v1/panel/partner_win`, {
          params: {
            base_player_name: this.playerOne,
            partner_player_name: this.playerTwo,
          },
        });
        this.matchData = response.data;

        const vsResponse = await axios.get(`/api/lol/v1/panel/partner_vs`, {
          params: {
            base_player_name: this.playerOne,
            vs_player_name: this.playerTwo,
          },
        });
        this.vsData = vsResponse.data
      } catch (err) {
        this.error = err.response?.data?.error_msg || "发生未知错误。";
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped>
.match-search-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f7f9fc;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #333;
}

.title {
  font-size: 2.5rem;
  font-weight: bold;
  color: #4a90e2;
  text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.input-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;
}

.search-box {
  width: 350px;
  margin-bottom: 15px;
  border-radius: 8px;
  background-color: #ffffff;
  color: #4a4a4a;
  border: 1px solid #d9e2ec;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.el-button.search-button {
  background: linear-gradient(45deg, #4a90e2, #6cc1ff);
  color: #ffffff;
  font-weight: bold;
  border-radius: 8px;
  padding: 8px 20px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}

.match-card {
  background-color: #ffffff;
  border: 1px solid #d9e2ec;
  border-radius: 12px;
  padding: 20px;
  color: #4a4a4a;
  margin-top: 20px;
  width: 320px;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.match-card:hover {
  transform: translateY(-5px);
}

.match-card h2 {
  color: #4a90e2;
  font-size: 1.5rem;
  text-align: center;
  margin-bottom: 15px;
}

.match-card p {
  font-size: 1.1rem;
  line-height: 1.6;
  margin-bottom: 10px;
  text-align: center;
}
</style>
