<template>
  <div>
    <Bar :data="chartData" :options="chartOptions"/>
  </div>
</template>

<script>
import {Bar} from "vue-chartjs";
import {Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale} from "chart.js";
import ChartDataLabels from 'chartjs-plugin-datalabels';

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, ChartDataLabels);

export default {
  name: "BarChart",
  components: {
    Bar,
  },
  props: {
    stats: {
      type: Object,
      required: true,
    },
  },
  computed: {
    chartData() {

      const labels = [
          '击杀(第' + this.stats.per_kill_rank + ')',
          '助攻(第' + this.stats.per_assist_rank + ')',
          '死亡(第' + this.stats.per_death_rank + ')',
          '伤害(k)(第' + this.stats.per_damage_rank + ')',
          '分均伤害(第' + this.stats.per_min_damage_rank + ')',
          '伤害转化(第' + this.stats.per_damage_trans_rank + ')',
          '经济(k)(第' + this.stats.per_gold_earned_rank + ')',
          '承伤(第' + this.stats.per_damage_taken_rank + ')',
          '视野(第' + this.stats.per_vision_rank + ')',
          '参团(第' + this.stats.participation_rank + ')',
      ];

      const summonerValues = [
        this.stats.total_player_count - this.stats.per_kill_rank + 1,
        this.stats.total_player_count - this.stats.per_assist_rank + 1,
        this.stats.total_player_count - this.stats.per_death_rank + 1,
        this.stats.total_player_count - this.stats.per_damage_rank + 1,
        this.stats.total_player_count - this.stats.per_min_damage_rank + 1,
        this.stats.total_player_count - this.stats.per_damage_trans_rank + 1,
        this.stats.total_player_count - this.stats.per_gold_earned_rank + 1,
        this.stats.total_player_count - this.stats.per_damage_taken_rank + 1,
        this.stats.total_player_count - this.stats.per_vision_rank + 1,
        this.stats.total_player_count - this.stats.participation_rank + 1,
      ];

      const maxValues = [
        this.stats.total_player_count,
        this.stats.total_player_count,
        this.stats.total_player_count,
        this.stats.total_player_count,
        this.stats.total_player_count,
        this.stats.total_player_count,
        this.stats.total_player_count,
        this.stats.total_player_count,
        this.stats.total_player_count,
        this.stats.total_player_count,
      ];

      return {
        labels: labels,
        datasets: [
          {
            label: '召唤师数据',
            backgroundColor: '#3498db',
            data: summonerValues, // 使用排名计算的值
            barThickness: 30, // 控制柱子的宽度

          },
          {
            label: '联赛最高',
            backgroundColor: '#e74c3c',
            data: maxValues, // 使用最大值
            barThickness: 30, // 控制柱子的宽度

          },
        ],
      };
    },
    chartOptions() {
      return {
        responsive: true,
        plugins: {
          legend: {
            labels: {
              color: '#000',
            },
          },
          // 自定义插件以在柱状图上展示数据
          datalabels: {
            anchor: 'end',
            align: 'start',
            color: '#000',
            formatter: (value, context) => {
              // 这里选择展示 realSummonerValues 或 realMaxValues
              return context.dataset.label === '召唤师数据'
                  ? this.realSummonerValues[context.dataIndex]
                  : this.realMaxValues[context.dataIndex];
            },
          },
        },
        scales: {
          y: {
            display: false, // 隐藏 Y 轴
            beginAtZero: true,
          },
          x: {
            beginAtZero: true,
          }
        },
      };
    },
    realSummonerValues() {
      return [
        this.stats.per_kill,
        this.stats.per_assist,
        this.stats.per_death,
        this.stats.per_damage,
        this.stats.per_min_damage,
        this.stats.per_damage_trans,
        this.stats.per_gold_earned,
        this.stats.per_damage_taken,
        this.stats.per_vision,
        this.stats.participation,
      ];
    },
    realMaxValues() {
      return [
        this.stats.max_kill,
        this.stats.max_assist,
        this.stats.max_death,
        this.stats.max_damage,
        this.stats.max_min_damage,
        this.stats.max_damage_trans,
        this.stats.max_gold_earned,
        this.stats.max_damage_taken,
        this.stats.max_vision,
        this.stats.max_participation,
      ];
    },
  },
  mounted() {
    // 在组件挂载后添加排名标签
    this.$nextTick(() => {
      this.addRankLabels();
    });
  },
  watch: {
    stats: {
      immediate: true,
      handler() {
        this.$nextTick(() => {
          this.addRankLabels();
        });
      },
    },
  },
};
</script>
