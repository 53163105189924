import Vue from 'vue';
import VueRouter from 'vue-router';
import HomePage from '@/views/HomePage.vue';
import RankPage from '@/views/RankPage.vue';
import ChampionRankPage from '@/views/ChampionRankPage.vue';
import PartnerPage from '@/views/PartnerPage.vue';
import PlayerHomePage from '@/views/PlayerHomePage.vue';
import SeasonPage from "@/views/SeasonPage.vue";

Vue.use(VueRouter);

const routes = [
  { path: '/', component: HomePage },
  { path: '/rank', component: RankPage },
  { path: '/champion-rank', component: ChampionRankPage },
  { path: '/partner', component: PartnerPage },
  { path: '/summoner', component: PlayerHomePage },
  { path: '/season', component: SeasonPage },
];

const router = new VueRouter({
  mode: 'hash', // 或者 'history'，根据需要选择
  routes,
});

export default router;
