<template>
  <div class="season-page">
    <!-- 赛季选择框 -->
    <el-row>
      <el-col :span="8">
        <el-form label-position="top">
          <el-form-item label="选择赛季">
            <el-select v-model="selectedSeason" placeholder="选择赛季" @change="fetchSeasonData">
              <el-option
                  v-for="season in seasons"
                  :key="season.season_id"
                  :label="season.season_name"
                  :value="season.season_id"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>

<!--    &lt;!&ndash; 赛季基础信息 &ndash;&gt;-->
<!--    <el-card class="season-info" shadow="hover">-->
<!--      <h2>赛季介绍</h2>-->
<!--      <p>{{ seasonInfo.description }}</p>-->
<!--    </el-card>-->

    <!-- 赛程安排 -->
    <el-card class="season-schedule" shadow="hover">
      <h2>赛程安排</h2>
      <el-carousel :interval="0" :loop="false" arrow="always" type="card" height="120px">
        <el-carousel-item v-for="match in schedule" :key="match.season_match_id">
          <div class="match-card">
            <div class="match-title">{{ match.season_match_name }}</div> <!-- 添加比赛标题 -->
            <div class="team-info">
              <div class="team-left">
                <img :src="match.match_team_one_icon" alt="战队图标" class="team-icon"/>
                <span class="team-name">{{ match.match_team_one_name }}</span>
              </div>
              <div class="score">{{ match.match_team_one_score }} : {{ match.match_team_two_score }}</div>
              <div class="team-right">
                <img :src="match.match_team_two_icon" alt="战队图标" class="team-icon"/>
                <span class="team-name">{{ match.match_team_two_name }}</span>
              </div>
            </div>
            <div class="match-status">{{ match.match_state }}</div>
            <div class="match-time">{{ match.match_time }}</div>
          </div>
        </el-carousel-item>
      </el-carousel>
    </el-card>

    <!-- 战队排行榜 -->
    <el-card class="team-rankings" shadow="hover">
      <h2>战队排行榜</h2>
      <el-table :data="teamRankings" style="width: 100%">
        <el-table-column label="排名" align="center" width="60">
          <template #default="scope">
            {{ scope.$index + 1 }}
          </template>
        </el-table-column>
        <el-table-column label="战队" align="center">
          <template #default="scope">
            <img :src="scope.row.club_icon" alt="战队图标" class="team-icon"/>
            {{ scope.row.club_name }}
          </template>
        </el-table-column>
        <el-table-column prop="club_score" label="积分" align="center" width="80"></el-table-column>
        <el-table-column prop="club_match_count" label="总场次" align="center" width="80"></el-table-column>
        <el-table-column prop="club_win" label="小胜场" align="center" width="80"></el-table-column>
        <el-table-column prop="club_loss" label="小负场" align="center" width="80"></el-table-column>
      </el-table>
    </el-card>
  </div>
</template>

<script>
export default {
  name: 'SeasonPage',
  data() {
    return {
      selectedSeason: null,
      seasons: [],
      seasonInfo: {},
      schedule: [],
      teamRankings: [],
    };
  },
  methods: {
    async fetchSeasons() {
      // 获取赛季选项的接口请求
      try {
        const response = await fetch("/api/lol/v1/panel/seasons"); // 赛季列表 API 请求地址
        const data = await response.json();
        this.seasons = data.season_list;
        if (this.seasons.length > 0) {
          this.selectedSeason = this.seasons[0].season_id;
          this.fetchSeasonData();
        }
      } catch (error) {
        console.error("获取赛季选项失败", error);
      }
    },
    async fetchSeasonData() {
      // 根据选定赛季ID获取赛季信息
      try {
        const response = await fetch(`/api/lol/v1/panel/season/${this.selectedSeason}/detail`); // 赛季数据 API 请求地址
        const data = await response.json();
        // this.seasonInfo = data.info;
        this.schedule = data.club_schedule;
        this.teamRankings = data.club_list;
      } catch (error) {
        console.error("获取赛季数据失败", error);
      }
    },
  },
  mounted() {
    // 初始化时获取赛季选项并选中第一个
    this.fetchSeasons();
  },
};
</script>

<style scoped>
.season-page {
  font-family: Arial, sans-serif;
  padding: 20px;
}

.season-info,
.season-schedule,
.team-rankings {
  margin-top: 20px;
}

.match-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid #dcdfe6;
  border-radius: 4px;
  padding: 10px;
  background-color: #f5f7fa;
  width: 300px; /* 根据需要调整宽度 */
}

.team-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  font-size: 18px; /* 增大队伍信息的字体大小 */
}

.team-left,
.team-right {
  display: flex;
  align-items: center;
}

.team-icon {
  width: 40px; /* 根据需要设置图标大小 */
  height: 40px; /* 根据需要设置图标大小 */
  margin-right: 8px; /* 队伍名称与图标间的间隔 */
}

.score {
  font-size: 24px; /* 调整比分字体大小 */
  margin: 0 20px; /* 调整比分与队伍信息的间隔 */
}

.match-status, .match-time {
  font-size: 12px; /* 减小时间和状态的字体大小 */
  color: #666; /* 可选：改变字体颜色以增加对比 */
}
</style>
