<template>
  <div class="welcome-container">
    <div class="background"></div>
    <div class="content">
      <h1 class="title">欢迎来到川发LOL内战系统</h1>
      <p class="subtitle">请从左侧导航栏选择功能</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HomePage',
};
</script>

<style scoped>
.welcome-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  position: relative;
  overflow: hidden;
}

.background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url('https://picx.zhimg.com/v2-c1d4c305d53dd58b3bb6a27a4a8f4b0a.jpeg') no-repeat center center;
  background-size: cover;
  filter: blur(0px); /* 虚化效果 */
  z-index: 1;
}

.content {
  position: relative;
  z-index: 2;
  color: #d0d2d3;
  text-align: center;
  padding: 20px;
}

.title {
  font-size: 2.5rem;
  font-weight: bold;
  color: #ffcc00;
  text-shadow: 2px 2px 10px rgba(0, 0, 0, 0.8);
  margin-bottom: 20px;
}
</style>
