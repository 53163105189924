<template>
  <div id="player-rank">
    <!-- 赛季选择框 -->
    <el-select
        v-model="selectedSeason"
        placeholder="选择赛季"
        style="margin-bottom: 20px; width: 300px;"
        @change="fetchRankData"
    >
      <el-option
          v-for="season in seasons"
          :key="season.season_id"
          :label="season.season_name"
          :value="season.season_id"
      />
    </el-select>
    <el-input
        v-model="searchQuery"
        placeholder="搜索召唤师名称"
        style="margin-bottom: 20px; width: 300px;"
        clearable
    ></el-input>
    <el-table
        v-if="filteredData.length"
        :data="filteredData"
        stripe
        style="width: 100%"
        v-loading="loading"
    >
      <!-- 增加排名字段 -->
      <el-table-column label="排名" width="100">
        <template slot-scope="scope">
          {{ scope.$index + 1 }}
        </template>
      </el-table-column>

      <el-table-column prop="player_name" label="召唤师昵称" sortable width="180">
        <template slot-scope="scope">
            <span :class="{ 'highlighted-player': scope.row.supporter === 1 }">
                {{ scope.row.player_name }}
            </span>
        </template>
      </el-table-column>

      <el-table-column prop="match_count" label="总场次" sortable width="100">
        <template slot-scope="scope">
              <span :class="{ 'highlighted-player': scope.row.supporter === 1 }">
                {{ scope.row.match_count }}
              </span>
        </template>
      </el-table-column>

      <el-table-column prop="win_count" label="胜场" sortable width="100">
        <template slot-scope="scope">
              <span :class="{ 'highlighted-player': scope.row.supporter === 1 }">
                {{ scope.row.win_count }}
              </span>
        </template>
      </el-table-column>

      <el-table-column prop="win_percent" label="胜率" sortable width="100">
        <template slot-scope="scope">
              <span :class="{ 'highlighted-player': scope.row.supporter === 1 }">
                {{ scope.row.win_percent }}%
              </span>
        </template>
      </el-table-column>

      <el-table-column prop="mvp_count" label="MVP数" sortable width="100">
        <template slot-scope="scope">
              <span :class="{ 'highlighted-player': scope.row.supporter === 1 }">
                {{ scope.row.mvp_count }}
              </span>
        </template>
      </el-table-column>

      <el-table-column prop="svp_count" label="SVP数" sortable width="100">
        <template slot-scope="scope">
              <span :class="{ 'highlighted-player': scope.row.supporter === 1 }">
                {{ scope.row.svp_count }}
              </span>
        </template>
      </el-table-column>

      <el-table-column prop="kda" label="KDA" sortable width="100">
        <template slot-scope="scope">
              <span :class="{ 'highlighted-player': scope.row.supporter === 1 }">
                {{ scope.row.kda }}
              </span>
        </template>
      </el-table-column>

      <el-table-column prop="economy_percent" label="平均经济占比" sortable width="130">
        <template slot-scope="scope">
              <span :class="{ 'highlighted-player': scope.row.supporter === 1 }">
                {{ scope.row.economy_percent }}%
              </span>
        </template>
      </el-table-column>

      <el-table-column prop="damage_percent" label="平均伤害占比" sortable width="130">
        <template slot-scope="scope">
              <span :class="{ 'highlighted-player': scope.row.supporter === 1 }">
                {{ scope.row.damage_percent }}%
              </span>
        </template>
      </el-table-column>

      <el-table-column prop="tank_damage_percent" label="平均承伤占比" sortable width="130">
        <template slot-scope="scope">
              <span :class="{ 'highlighted-player': scope.row.supporter === 1 }">
                {{ scope.row.tank_damage_percent }}%
              </span>
        </template>
      </el-table-column>

      <el-table-column prop="damage_conversion" label="伤害转化率" sortable width="150">
        <template slot-scope="scope">
              <span :class="{ 'highlighted-player': scope.row.supporter === 1 }">
                {{ scope.row.damage_conversion }}%
              </span>
        </template>
      </el-table-column>

      <el-table-column prop="avg_wards_placed" label="平均插眼数" sortable width="130">
        <template slot-scope="scope">
              <span :class="{ 'highlighted-player': scope.row.supporter === 1 }">
                {{ scope.row.avg_wards_placed }}
              </span>
        </template>
      </el-table-column>

      <el-table-column prop="avg_game_rating" label="平均游戏评分" sortable width="130">
        <template slot-scope="scope">
              <span :class="{ 'highlighted-player': scope.row.supporter === 1 }">
                {{ scope.row.avg_game_rating }}
              </span>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'RankPage',
  data() {
    return {
      rankData: [],
      searchQuery: '', // 搜索输入框的绑定字段
      sortedData: [],
      loading: false,
      error: null,
      seasons: [], // 存储赛季选项
      selectedSeason: null, // 选中的赛季ID
    };
  },
  computed: {
    filteredData() {
      // 根据搜索框的内容过滤数据
      return this.sortedData.filter(player =>
          player.player_name.toLowerCase().includes(this.searchQuery.toLowerCase())
      );
    },
  },
  created() {
    this.fetchSeasons();
  },
  methods: {
    async fetchSeasons() {
      try {
        const response = await axios.get('/api/lol/v1/panel/seasons'); // 获取赛季数据的接口
        this.seasons.push({ season_id: '', season_name: '总排行榜' }); // 添加一个总排行榜选项
        this.seasons.push(...response.data.season_list); // 将获取到的赛季数据添加到选项中
        if (this.seasons.length > 0) {
          this.selectedSeason = this.seasons[0].season_id; // 默认选择第一个赛季
          this.fetchRankData(); // 加载初始赛季的数据
        }
      } catch (err) {
        this.error = err.response?.data?.error_msg || '无法加载赛季数据。';
      }
    },
    async fetchRankData() {
      this.loading = true;
      try {
        const response = await axios.get(`/api/lol/v1/panel/player_rank?season_id=${this.selectedSeason}`);
        this.rankData = response.data.rank_list;
        this.sortedData = [...this.rankData];
      } catch (err) {
        this.error = err.response?.data?.error_msg || '无法加载排名数据。';
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped>
#player-rank {
  margin: 20px;
}

/* 彩虹动态效果 */
.highlighted-player {
  background: linear-gradient(45deg, #ff0066, #ffcc00, #00ccff, #ff33cc);
  background-size: 400% 400%;
  animation: gradientAnimation 5s ease infinite;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

@keyframes gradientAnimation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

/* 表格样式 */
.el-table th {
  background-color: #2c3e50;
  color: #fff;
}

.el-table td {
  background-color: rgba(44, 62, 80, 0.9);
  color: #fff;
}
</style>
