<template>
  <div class="summoner-home">
    <!-- 赛季选择框 -->
    <el-select
        v-model="selectedSeason"
        placeholder="选择赛季"
        style="margin-bottom: 20px; width: 300px;"
        @change="fetchSummonerData"
    >
      <el-option
          v-for="season in seasons"
          :key="season.season_id"
          :label="season.season_name"
          :value="season.season_id"
      />
    </el-select>
    <el-input
        v-model="summonerName"
        placeholder="输入召唤师名称搜索"
        @keyup.enter="fetchSummonerData"
        clearable
        class="search-box"
    />
    <el-button @click="fetchSummonerData" type="primary">搜索</el-button>

    <div v-if="summonerData" class="info-section">
      <h2>{{ summonerData.player_name }}</h2>
      <h3>擅长位置：{{ summonerData.position }}</h3>

      <div class="hero-pool">
        <h3>擅长英雄池</h3>
        <div v-for="hero in summonerData.champion_pool" :key="hero.player_id" class="hero-card">
          <img :src="hero.champion_icon" alt="Hero Icon" class="hero-icon"/>
          <div class="hero-stats">
            <p>场次：{{ hero.pick_count }}</p>
            <p>胜率：{{ hero.win_percent }}%</p>
          </div>
        </div>
      </div>

      <div class="chart-container">
        <div class="radar-chart">
          <h3>战力图</h3>
          <div ref="radarChart" style="width: 400px; height: 400px;"></div>
        </div>
        <div class="bar-chart">
          <h3>数据图</h3>
          <BarChart :stats="summonerData.stats"/>
        </div>
      </div>
    </div>

    <div class="recent-matches" v-if="recentMatches.length">
      <h3>近期比赛</h3>
      <el-table :data="recentMatches" style="width: 100%" class="compact-table">
        <el-table-column prop="date" label="日期"/>
        <el-table-column label="结果">
          <template slot-scope="scope">
            <span :style="{ color: scope.row.win ? '#3498db' : '#e74c3c' }">
              {{ scope.row.win ? '胜' : '败' }}
            </span>
          </template>
        </el-table-column>
        <el-table-column label="英雄">
          <template slot-scope="scope">
            <img :src="scope.row.champion_icon" alt="Champion Icon" class="champion-icon"/>
          </template>
        </el-table-column>
        <el-table-column label="K/D/A">
          <template slot-scope="scope">
            {{ scope.row.kill }}/{{ scope.row.death }}/{{ scope.row.assist }}
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
          @current-change="fetchRecentMatches"
          :current-page="currentPage"
          :page-size="pageSize"
          :total="totalMatches"
          layout="prev, pager, next"
          class="compact-pagination">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";
import axios from "axios";
import {nextTick} from "vue";
import BarChart from "@/components/BarChart";

export default {
  name: "PlayerHomePage",
  data() {
    return {
      summonerName: "",
      summonerId: 0,
      summonerData: null,
      recentMatches: [],
      currentPage: 1,
      pageSize: 10,
      totalMatches: 0,
      radarChart: null,
      barChart: null,
      seasons: [], // 存储赛季选项
      selectedSeason: null, // 选中的赛季ID
    };
  },
  components: {
    BarChart,
  },
  created() {
    this.fetchSeasons();
  },
  methods: {
    async fetchSeasons() {
      try {
        const response = await axios.get('/api/lol/v1/panel/seasons'); // 获取赛季数据的接口
        this.seasons.push({season_id: '', season_name: '总排行榜'}); // 添加一个总排行榜选项
        this.seasons.push(...response.data.season_list); // 将获取到的赛季数据添加到选项中
        if (this.seasons.length > 0) {
          this.selectedSeason = this.seasons[0].season_id; // 默认选择第一个赛季
          this.fetchSummonerData(); // 加载初始赛季的数据
        }
      } catch (err) {
        this.error = err.response?.data?.error_msg || '无法加载赛季数据。';
      }
    },

    async fetchSummonerData() {
      try {
        const response = await axios.get(`/api/lol/v1/panel/player_detail`, {
          params: {player_name: this.summonerName, season_id: this.selectedSeason},
        });
        this.summonerData = response.data;
        this.summonerId = this.summonerData.player_id;
        await nextTick();
        this.initRadarChart(this.summonerData.stats);
        console.log(this.summonerData.stats);
        this.currentPage = 1;
        await this.fetchRecentMatches();
      } catch (error) {
        console.error("Error fetching summoner data:", error);
      }
    },

    async fetchRecentMatches(page = 1) {
      try {
        const response = await axios.get(`/api/lol/v1/panel/player/matches`, {
          params: {player_id: this.summonerId, page: page, page_size: this.pageSize, season_id: this.selectedSeason},
        });
        this.recentMatches = response.data.match_list;
        this.totalMatches = response.data.total;
      } catch (error) {
        console.error("Error fetching matches:", error);
      }
    },

    initRadarChart(stats) {
      if (!this.$refs.radarChart) {
        console.error("Radar chart container does not exist.");
        return;
      }

      if (this.radarChart) this.radarChart.dispose();
      this.radarChart = echarts.init(this.$refs.radarChart);

      const option = {
        radar: {
          indicator: [
            {name: "击杀", max: stats.total_player_count},
            {name: "助攻", max: stats.total_player_count},
            {name: "生存", max: stats.total_player_count},
            {name: "经济", max: stats.total_player_count},
            {name: "视野", max: stats.total_player_count},
            {name: "参团", max: stats.total_player_count},
          ],
        },
        series: [
          {
            type: "radar",
            data: [
              {
                value: [
                  stats.total_player_count - stats.per_kill_rank,
                  stats.total_player_count - stats.per_assist_rank,
                  stats.per_death_rank,
                  stats.total_player_count - stats.per_gold_earned_rank,
                  stats.total_player_count - stats.per_vision_rank,
                  stats.total_player_count - stats.participation_rank,
                ],
              },
            ],
          },
        ],
      };

      this.radarChart.setOption(option);
    },

  },
  beforeDestroy() {
    if (this.radarChart) this.radarChart.dispose();
    if (this.barChart) this.barChart.dispose();
  },
};
</script>

<style scoped>
.summoner-home {
  padding: 20px;
}

.search-box {
  margin-bottom: 20px;
  width: 300px;
}

.info-section {
  margin-top: 20px;
}

.hero-pool {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.hero-card {
  text-align: center;
}

.hero-icon {
  width: 60px;
  height: 60px;
}

.hero-stats {
  font-size: 14px;
}

/* 中央对齐的图表容器 */
.chart-container {
  display: flex;
  justify-content: center;
  gap: 40px; /* 增加间距，使图表不紧贴 */
  margin-top: 20px;
}

.radar-chart {
  width: 400px;
  height: 400px;
}

.bar-chart {
  width: 800px;
  height: 400px;
}

.recent-matches {
  font-size: 14px;
}

.compact-table .el-table__row {
  height: 32px;
}

.champion-icon {
  width: 30px;
  height: 30px;
}

.el-table__header-wrapper th, .el-table__body-wrapper td {
  padding: 4px 8px;
}

.compact-pagination {
  margin-top: 8px;
}

.win {
  color: blue;
}

.lose {
  color: red;
}

</style>
