<template>
  <div id="app">
    <el-container>
      <el-aside width="200px">
        <el-menu
            :default-active="activeMenuIndex"
            class="el-menu-vertical-demo"
            @select="handleMenuSelect"
            background-color="#545c64"
            text-color="#fff"
            active-text-color="#ffd04b"
        >
          <el-menu-item index="1">
            <i class="el-icon-house"></i>
            <span slot="title">主页</span>
          </el-menu-item>
          <el-menu-item index="2">
            <i class="el-icon-user"></i>
            <span slot="title">召唤师排行榜</span>
          </el-menu-item>
          <el-menu-item index="3">
            <i class="el-icon-s-data"></i>
            <span slot="title">英雄数据排行榜</span>
          </el-menu-item>
          <el-menu-item index="4">
            <i class="el-icon-s-data"></i>
            <span slot="title">好基友查询</span>
          </el-menu-item>
          <el-menu-item index="5">
            <i class="el-icon-s-data"></i>
            <span slot="title">召唤师主页</span>
          </el-menu-item>
          <el-menu-item index="6">
            <i class="el-icon-s-data"></i>
            <span slot="title">赛事信息</span>
          </el-menu-item>
        </el-menu>
      </el-aside>

      <el-main>
        <keep-alive>
          <router-view></router-view>
        </keep-alive>
      </el-main>
    </el-container>
  </div>
</template>

<script>
export default {
  name: 'App',
  data() {
    return {
      currentComponent: 'HomePage', // 默认显示的页面
      summonerName: '', // 记录召唤师名称
    };
  },
  computed: {
    activeMenuIndex() {
      // 根据当前路由的路径返回相应的菜单索引
      const path = this.$route.path;
      if (path === '/') return '1';
      if (path === '/rank') return '2';
      if (path === '/champion-rank') return '3';
      if (path === '/partner') return '4';
      if (path === '/summoner') return '5';
      if (path === '/season') return '6';
      return '1'; // 默认返回主页
    }
  },
  methods: {
    handleMenuSelect(index) {
      // 根据菜单点击，切换到对应的路由
      if (index === '1') {
        this.$router.push('/');
      } else if (index === '2') {
        this.$router.push('/rank');
      } else if (index === '3') {
        this.$router.push('/champion-rank');
      } else if (index === '4') {
        this.$router.push('/partner');
      } else if (index === '5') {
        this.$router.push('/summoner');
      } else if (index === '6') {
        this.$router.push('/season');
      }
    },
  },
};
</script>

<style>
#app {
  height: 100vh;
}

.el-aside {
  background-color: #545c64;
}

.el-main {
  padding: 20px;
  background-color: #f2f2f2;
  height: 100vh;
  overflow-y: auto;
}
</style>
